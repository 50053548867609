import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Constants } from '../constants';
import { saveAs } from 'file-saver';
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  constructor(private http: HttpClient) {}

  uploadExcel(brand: string, service: string, formData: FormData) {
    return this.http.post(this.uri(brand, service), formData);
  }

  downloadExcel(brand: string, service: string) {
    this.http
      .get(this.uri(brand, service), {
        responseType: 'arraybuffer',
        observe: 'response'
      })
      .subscribe((response) => {
        const contentDisposition = response.headers.get('content-disposition');
        // Keep this because otherwise file gets named <filename>.xlsx.xlsx
        const filename = contentDisposition.split('filename=')[1].split('.')[0];
        const fileBlob = new Blob([response.body], {
          type: 'application/ms-excel'
        });
        saveAs(fileBlob, filename + '.xlsx');
      });
  }

  uri(brand: string, service: string): string {
    return `excel-service/${Constants.serviceUri(brand, service)}/excel`;
  }
}
