<div class="card mar-top-large">
  <div name="card_content" class="card__content pad-medium">
    <div class="row">
      <h3 class="h5 left cursor-default col md-11 xs-10">
        {{ service.name }}
      </h3>

      <details class="dropdown dropdown--left col md-1 xs-2" *ngIf="editable">
        <summary class="dropdown__indicator right"></summary>
        <div class="dropdown__content">
          <ul class="list list--no-link-icon pad-horizontal">
            <li>
              <a name="edit-{{ service.brand }}-{{ service.modId }}" style="cursor: pointer" (click)="onServiceEdit()"
                ><i class="icon i-edit"></i>{{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.MOREOPTIONS.EDIT' | translate }}
              </a>
            </li>
            <li>
              <a
                name="delete-{{ service.brand }}-{{ service.modId }}"
                style="cursor: pointer"
                (click)="onServiceDelete()"
              >
                <i class="icon i-delete"></i>
                {{ 'SERVICE_DETAIL_FAILURE_LIST.TABLE.MOREOPTIONS.DELETE' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </details>

      <div class="clearfix"></div>
    </div>

    <div
      class="card service-summary"
      id="service-details-{{ service.brand }}-{{ service.modId }}"
      (click)="onShowDetails()"
    >
      <div class="card__content">
        <div>
          <div class="element"><i class="icon i-bookmark"></i> {{ service.modId }}</div>
          <div class="element"><i class="icon i-car"></i> {{ service.brand }}</div>
          <div class="element"><i class="icon i-car"></i> {{ service.generation }}</div>
          <div class="element">
            <i class="icon i-clock"></i>
            {{ service.launchDate | date: dateFormat }}
          </div>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>

  <app-affirmation-modal
    (closed)="onAffirmationServiceModalClosed($event)"
    *ngIf="openAffirmationServiceModal"
    [title]="'AFFIRMATION_MODAL.HEADER' | translate"
    [content]="
      'AFFIRMATION_MODAL.CONTENT'
        | translate
          : {
              x: service.name,
              y: service.modId,
              z: service.launchDate
            }
    "
    [confirmContent]="'AFFIRMATION_MODAL.DELETE_YES' | translate"
    [cancelContent]="'AFFIRMATION_MODAL.DELETE_NO' | translate"
  >
  </app-affirmation-modal>
</div>
